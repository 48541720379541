import React, { useState } from "react";

import { IconContext } from "react-icons";
import { IoWalletOutline, IoMenu, IoClose } from "react-icons/io5";
import { ReactComponent as Senseifi } from "../assets/senseifi.svg";
import { ReactComponent as SenseifiIcon } from "../assets/senseifi-icon.svg";
import NavbarItems from "./NavbarItems";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";

import { useChain } from "@cosmos-kit/react";
import { chainName } from "../config/sei";
import { truncateAddress } from "../utils";

export default function Navbar({
  setIsWMVisible,
}: {
  setIsWMVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [menuVis, setMenuVis] = useState<boolean>(false);
  var menuClass = menuVis
    ? "z-10 absolute h-screen ease-in-out duration-300 translate-x-0 opacity-100 w-full h-full bg-neutral-900  h-[100vh] p-10"
    : "z-10 fixed h-screen ease-in-out duration-300 -translate-x-full opacity-0 w-full h-full bg-neutral-900 h-[100vh] p-10";

  return (
    <>
      <header className="relative top-0 z-50  bg-blue-dark py-2 backdrop-blur-lg">
        <nav>
          {NavbarElement(menuVis, setMenuVis, setIsWMVisible)}
          <div className={menuClass}>
            <IconContext.Provider
              value={{
                className: "ml-auto mr-5 h-24 text-white text-2xl ",
              }}
            >
              <div onClick={() => setMenuVis(false)}>
                <IoClose />
              </div>
            </IconContext.Provider>
            <div onClick={() => setMenuVis(false)}>
              <NavbarItems />
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

const NavbarElement = (
  menuVis: boolean,
  setMenuVis: React.Dispatch<React.SetStateAction<boolean>>,
  setIsWMVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const chain = useChain(chainName);
  return (
    <div className="m-auto flex w-full max-w-screen-2xl items-center justify-between px-3 ">
      <a href="http://senseifi.io">
        {" "}
        <Senseifi className="h-16" />
      </a>
      <IconContext.Provider
        value={{
          className: "block lg:hidden mr-6 text-white text-4xl focus:none ",
        }}
      >
        <div className="flex">
          <div onClick={() => setMenuVis(!menuVis)}>
            <IoMenu />
          </div>
        </div>
      </IconContext.Provider>
      <div className="hidden items-center lg:flex ">
        {/* <NavbarItems /> */}
        <div className="mr-5 flex gap-5">
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="https://t.me/SenSeiFi" target="_blank">
              <FaTelegramPlane />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="https://twitter.com/SenSei_DeFi" target="_blank">
              <FaTwitter />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="https://discord.gg/b6h3paCYy4" target="_blank">
              <FaDiscord />
            </a>
          </IconContext.Provider>
        </div>
        <div
          className="mx-6 hidden items-center shadow-windows bg-neutral-300 border-2 px-5 py-2 font-bold text-blue-dark lg:flex cursor-pointer hover:border-white hover:bg-neutral-400 hover:text-white"
          onClick={chain.openView}
        >
          {chain.isWalletConnected
            ? truncateAddress(chain.address)
            : "Connect Wallet"}
        </div>
      </div>
    </div>
  );
};
