import React, { useEffect, useRef, useState } from "react";
import { useChain } from "@cosmos-kit/react";
import { chainName } from "../config/sei";

const GameComponent = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const chain = useChain(chainName);

  const [search, setSearch] = useState("player");

  useEffect(() => {
    if (chain.isWalletConnected) {
      setSearch(chain.address);
    }
  }, [chain.isWalletConnected, chain.address]);

  return (
    <div id="iframe-container" className="w-full h-0 pb-[31.25%]">
      <div
        id="iframe-parent"
        className="absolute top-0 right-0 bottom-0 left-0"
      >
        <iframe
          ref={iframeRef}
          src={"https://sensail-game.pages.dev?" + search}
          title="SenSeil Integration"
          className="w-full h-full border-0"
        />
      </div>
    </div>
  );
};

export default GameComponent;
