import React, { useState } from "react";

import "./App.css";
import "./input.css";
import GameComponent from "./components/GameComponent";
import Navbar from "./components/Navbar";

import { ChainProvider } from "@cosmos-kit/react";
import { chains, assets } from "chain-registry";
import { wallets as keplrWallets } from "@cosmos-kit/keplr";
import { wallets as leapWallets } from "@cosmos-kit/leap";
import { wallets as coin98Wallets } from "@cosmos-kit/coin98-extension";
import { seiTestnet2AssetList, seiTestnet2Chain } from "./config/sei";
import { WalletConnectOptions } from "@cosmos-kit/core";

const App = () => {
  const [isWMVisbile, setIsWMVisible] = useState<boolean>(false); //Wallet Modal
  const walletRepo = ["leap", "keplr"];

  return (
    <ChainProvider
      chains={[...chains, seiTestnet2Chain]}
      assetLists={[...assets, seiTestnet2AssetList]}
      wallets={[keplrWallets[0], ...leapWallets, ...coin98Wallets]}
      wrappedWithChakra={true}
      // modalViews={{ Error: WalletErrorView, NotExist: WalletNotExistView }}
      includeAllWalletsOnMobile={true}
      // walletConnectOptions={wc}
    >
      <div className="App">
        <Navbar setIsWMVisible={setIsWMVisible} />

        <GameComponent />
      </div>
    </ChainProvider>
  );
};

export default App;
